html,
body,
#__next {
  margin: 0;
}

.content {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.content img,
.content video {
  max-height: 80vh;
  max-width: 80vh;
  border-radius: 4px;
  margin: 16px auto;
}

* {
  font-family: 'Quicksand', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
